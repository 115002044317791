
import Instance from "./axios"

 const getdata=(time,result,pageNo,orgId,...argument)=>{

    let startTime=time[0].getTime()
    let endTime=time[1].getTime()
    if(argument.length>4)
    return Instance.post("/quickCheck/org/getDataList",{
        startTime,
        endTime,
        result,
        pageNo,
        orgId,
        pageSize:argument[0],
        "status":argument[1],
        "checker":argument[2],
        "item":argument[3],
        "sampleType":argument[4]
    })
    else if(argument.length>3)
    return Instance.post("/quickCheck/org/getDataList",{
        startTime,
        endTime,
        result,
        pageNo,
        orgId,
        pageSize:argument[0],
        "status":argument[1],
        "checker":argument[2],
        "item":argument[3]
    })
    else  if(argument.length>2)
    return Instance.post("/quickCheck/org/getDataList",{
        startTime,
        endTime,
        result,
        pageNo,
        orgId,
        pageSize:argument[0],
        "status":argument[1],
        "checker":argument[2]
    })
    else if(argument.length>1)
    Instance.post("/quickCheck/org/getDataList",{
        startTime,
        endTime,
        result,
        pageNo,
        orgId,
        pageSize:argument[0],
        "status":argument[1]
    })
    else
    return Instance.post("/quickCheck/org/getDataList",{
        startTime,
        endTime,
        result,
        pageNo,
        orgId,
        pageSize:argument[0]
    })

}
const getdata2=(time,areaID,pageNo,pageSize)=>{
    let startTime=time[0].getTime()
    let endTime=time[1].getTime()
    return Instance.post("/quickCheck/area/getDataList",{
        startTime,
        endTime,
        areaID,
        pageNo,
        pageSize,
    })
}
const getAreaAuditData=(time,areaID,pageNo,pageSize)=>{
    let startTime=time[0].getTime()
    let endTime=time[1].getTime()
    return Instance.post("/quickCheck/area/getDataList",{
        startTime,
        endTime,
        result:'2',
        areaID,
        pageNo,
        pageSize,
    })
}
const getAuditData=(recordId)=>{
    return new Promise((resolve,reject)=>{
        if(recordId)
            Instance.get('/audit/records/'+recordId).then(
                res=>{
                    if(res.data&&res.data.length>0)
                    resolve(res.data)
                    else
                    reject(new Error('数据无法识别'))
                },e=>{reject(e)}
            )
        else
        reject(new Error('参数为空,拒绝访问服务器'))
    })

}

const uploadData=function(data){
        if(data==undefined)
            throw new Error('上传数据为空')

        data.account_id=sessionStorage.getItem('currentUser')
       return  Instance.post("quickCheck/upload",data)
}


const  deleteRecord= (id)=>{
        return new Promise((resolve,reject)=>{
            if(id)
            Instance.delete('quickCheck/delete/'+id).then(
                res=>{
                    resolve(res)
                },e=>{
                    reject(e)
                }
            )
        })
}

export  {getdata as getDataList ,getdata2 as getAreaData,uploadData,getAreaAuditData,getAuditData,deleteRecord} 