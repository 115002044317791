<template>
  <div id="audit" class="page-root">
    <div id="selector-container">
      <el-form ref="form" inline :model="searchForm" :rules="rules">
        <el-form-item label="单位" v-if="userRole==='org'"  prop="area">
        <el-select size="small" v-model="org" placeholder="选择单位" clearable  @change = "selectArea">
          <el-option-group v-for="[key, value] in Object.entries(companyCaches)" :label="key" :key="key">
              <el-option v-for="org in value" :key="org.org_id" :label="org.org_name" :value="org.org_id"></el-option>
            </el-option-group>
        </el-select>
        </el-form-item>
        <el-form-item label="省" v-if="provinceCaches.length > 0 && userRole!=='org'" prop="area">
          <el-select size="small" v-model="province" placeholder="选择省" clearable @change="getCity">
            <el-option v-for="item in provinceCaches" :key="item[0]" :label="item[1]" :value="item[0]">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item v-if="cityOptions.length > 0" label="地区" prop="area">
          <el-select size="small" v-model="city" placeholder="选择地区" clearable @change="getCounty">
            <el-option v-for="item in cityOptions" :key="item[0]" :label="item[1]" :value="item[0]">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item v-if="countyOptions.length > 0" label="选择区县" prop="area">
          <el-select v-model="county" placeholder="选择地区" clearable
            @change="selectArea" size="small">
            <el-option v-for="item in countyOptions" :key="item[0]" :label="item[1]" :value="item[0]">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="时间" prop="time">
          <el-date-picker :default-time="[new Date(2000, 1, 1, 0, 0, 0),new Date(2000, 1, 1,23, 59, 59),]" size="small" v-model="searchForm.time" type="daterange" unlink-panels range-separator="To">
          </el-date-picker>
        </el-form-item>
        <!-- <el-form-item label="处理状态" prop="status">
          <el-select v-model="searchForm.status" size="small" placeholder="选择状态">
            <el-option label="未处理" value="0" />
            <el-option label="已处理" value="1" />
            <el-option label="待定" value="2" />
          </el-select>
        </el-form-item> -->
        <el-form-item style="flex:0 0 10vw;" >
          <el-button class="btn-search" type="primary" size="small" @click="submit()" :disable="searching">查询</el-button>
          <el-button class="btn-reset" type="" size="small" @click="$refs.form.resetFields()"> 重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="tool-bar">
      <div style="width:10vw;font-size:1.5vh;">
        <span>总计:{{total}}</span>
      </div>
      <el-button size="small" type="success" @click="downloadtemplate()">导出</el-button>
    </div>
    <div id="table-container" class="table-area">
       <!-- style修改头部背景，边框，高亮背景  -->
      <el-table style="--el-table-header-bg-color:#165191;--el-table-border-color:none;--el-table-row-hover-bg-color:#0d6c9f" :data="table" :cell-style="redCell" :row-style="rowStyle" height="69vh" :header-row-style="headerRowStyle"
        :load="searching" @expand-change="readSettleRecords" :row-key="row=>row.check_record_id">
        <el-table-column type="index" label="No" :index="tableIndex" />
        <el-table-column prop="check_time" label="日期" :formatter="dateTranser" />
        <el-table-column prop="check_time" label="检测时间" :formatter="timeTranser" />
        <el-table-column prop="send_company" label="被检单位" />
        <el-table-column prop="company_name" label="检测单位" />
        <el-table-column prop="sample" label="样品" />
        <el-table-column prop="check_item" label="检测项目" />
        <el-table-column prop="ref_info" label="标准" />
        <el-table-column prop="check_value" label="检测值" />
        <el-table-column prop="checker" label="检测员" />
        <el-table-column prop="time" type="expand" label="操作 " fixed="right">
          <template #default="scope">
            <div style="display:flex;flex-direction:column;align-items: center;justify-content: flex-start;"></div>
            <el-timeline v-if="scope.row.records">
              <el-timeline-item v-for="(record, index) in scope.row.records" :key="index" :timestamp="new Date(record.settle_time).toLocaleString()">
                {{settleStatus(record.settle)}} by {{record.settle_person}}
              </el-timeline-item>
            </el-timeline>
            <div style="height:4vh;padding-left: 5vw;">
              <el-button size="small" type="warning">延期上市</el-button>
              <el-button size="small" type="danger">作废</el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination small layout="->,prev, pager, next" background :total="total" :page-size="pageSize" @current-change="changePage">
      </el-pagination>
      <el-dialog v-if="true" ref="detailDialog" v-model="settleRecordsVisible" :append-to-body="false" center top="3vh">
      </el-dialog>
    </div>
  </div>
</template>
<script >
import { getDataList, getAreaAuditData, getAuditData } from "network/checkdata";
import { getAreaInfo, getSubareaList } from "../../assets/js/network/area";
import { getOrgDetail } from "../../assets/js/network/org";
export default {
  data() {
    return {
      searchForm: {
        time: null,
        status: null,
        area: null
      },
      rules: {
        area: [{ required: true, message: "区域不能为空", trigger: "change" }],
        time: [{ required: true, message: "时间不能为空", trigger: "change" }],
        // status: [
        //   { required: true, message: "处理状态不能为空", trigger: "change" },
        // ],
      },
      orgOptions:[],
      org:null,
      province: null,
      cityOptions: [],
      city: null,
      countyOptions: [],
      county: null,
      table: [],
      total: 0,
      currentPage: 1,
      pageSize: 20,
      tableLoading: false,  
      searching: false,
       headerRowStyle: {
        "font-size": "1.5vh",
        height: "2vh",
        "line-height": "2vh",
        color: "black",
        border: "1px solid black",
        'background-color':"#165191"
      },
    };
  },
  computed: {

    companyCaches(){
      return this.$store.getters['companies/userCompanies']
    },

    provinceCaches(){
      return this.$store.getters['areas/provinces']
    },
    cityCaches(){
      return this.$store.getters['areas/cities']
    },
    countyCaches(){
      return this.$store.getters['areas/areas']
    },
    tableIndex() {
      return (this.currentPage - 1) * this.pageSize + 1
    },
    userAuth() {
      return this.$store.getters.getUserAuth;
    },
    userRole() {
      return this.$store.getters.getUserRole;
    },
  },
  methods: {
    
    rowStyle: ({row,rowIndex }) => {
      console.log(row,rowIndex )
      let style = {
        height: "3vh",
        fontSize: "1.5vh",
        'background-color':'#013380',
        border:'none',
        color:'#f2f2f2'
      }
      if(rowIndex %2===0){
        style['background-color'] = '#06237a'
      }
     
      return style
    },
    setDefaultDate(){
        let firstdayofmounth=new Date()
        firstdayofmounth.setDate(1)
          this.searchForm.time=[firstdayofmounth,new Date()]
    },

    selectArea(val){
      if(val&&val !== ''  )
      this.searchForm.area=val
      else if(this.city!==""&&this.city!==null)
      this.searchForm.area=this.city
    },
    settleStatus(statusCode){
      switch(statusCode)
      {
        case '0':
          return '创建'
        case '1':
          return '核验'
        case '2':
          return '注销'
        default:
          return '未知状态'
      }
    },
    changePage: function (page) {
      this.currentPage = page
      this.submit();
    },
    async readSettleRecords(row, expandedRows) {
      if(expandedRows==null)
        return
      if (row.check_record_id && row.records == undefined)
      await  getAuditData(row.check_record_id).then(
          res => { if (!row.records) row.records = res},
          e => { console.log(e.message);this.$message.error(e.message) }
        )
    },
    
    redCell: (table) => {
      if (table.column.property == 'check_result')
        if (table.row[table.column.property] == 2)
          return {
            color: 'red'
          }
      
    },
     rowStyle: ({row,rowIndex }) => {
      console.log(row,rowIndex )
      let style = {
        height: "3vh",
        fontSize: "1.5vh",
        'background-color':'#06237a',
        border:'none',
        color:'#f2f2f2'
        //'background-color':'transparent'
      }
      if(rowIndex %2===0){
        style['background-color'] = '#013380'
      }
      if (row["check_result"] == 2)
        style.color = "red"
      if (row["status"] === "2") {
        style.textDecoration = "line-through"
      }
      return style
    },
    dateTranser: (row, column) => {
      let result = row[column.property];
      return new Date(result).toLocaleDateString();
    },
    timeTranser: (row, column) => {
      let result = row[column.property];
      return new Date(result).toLocaleTimeString();
    },
    submit() {
      this.searching = true;
      let form = this.$refs.form;
      if (form)
        form.validate((valid) => {
          if (valid) {
            this.tableLoading = true;
            if (this.userRole !== 'org')
              getAreaAuditData(this.searchForm.time, this.searchForm.area, this.currentPage, this.pageSize).then(
                res => {
                  this.searching = false
                  this.table = res.data.dataList
                  this.total = res.data.total
                }, e => { console.log(e.message); this.$message.error(e.message) }
              )
              else
              getDataList(this.searchForm.time,'2' ,this.currentPage,this.searchForm.area,  this.pageSize).then(
                res=>{
                  this.searching=false
                  this.table=res.data.dataList
                  this.total = res.data.total
                },e => { console.log(e.message); this.$message.error(e.message) }
              )
          }
        });
      this.searching = false;
    },
    output() {
      if (this.table.length == 0) alert("NO DATA !");
    },

    loadingAuth() {
      let auths = this.userAuth
      if (this.userRole === 'org')
        getOrgDetail(auths).then(
          res=>{
            this.orgOptions=res.data
          },e=>{console.log(e.message)}
        )
      else
      for (let auth of auths) {
        console.log("i")
        if (auth.match(/\d{2}0000/))
          getAreaInfo(auth).then(
            res => {
              this.provinceOptions.push(...res.data)
            },
            e => { console.log(e.message) }
          )
        else if (auth.match(/\d{4}00/))
          getAreaInfo(auth).then(
            res => {
              this.cityOptions.push(...res.data)
            },
            e => { console.log(e.message) }
          )
        else if (auth.match(/\d{4}00/))
          getAreaInfo(auth).then(
            res => {
              this.countyOptions.push(...res.data)
            },
            e => { console.log(e.message) }
          )
      }
    },
    getCity(val) {
      this.city=null
      this.county=null
      if (val === '' || val === undefined){
        this.searchForm.area=null
        return 
      }
      this.searchForm.area = val

      this.cityOptions=this.cityCaches.filter(e=>e[0].startsWith(val.substring(0,2)))

    },
    getCounty(val) {
      this.county=null
      if (val === '' || val === null)
      {
        this.searchForm.area=null
        return
      }
      this.searchForm.area = val
      this.countyOptions=this.countyCaches.filter(e=>e[0].startsWith(val.substring(0,4)))
    },
    destroy(index) { },
    refuse(index) { },
    deleteit(index) { },
    prepare(){
      if(this.provinceCaches.length<=0||this.provinceCaches===null){
        if(this.cityCaches&&this.cityCaches.length>0)
          this.cityOptions=this.cityCaches
        else
          this.countyOptions=this.countyCaches
    }
      
    }
  },
  props: {},
  created() {
    // this.loadingAuth()
    this.setDefaultDate()
    this.prepare()
  },
  mounted() { },
  updated() { },
};
</script>
<style lang="less" scoped>
.page-root {
  width: 90vw;
  height: 86vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}

#audit {
  .tool-bar {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: calc(100% - 5vw);
    height: 4vh;
    color: #f2f2f2;
    
  // background-color: #071f63;

    .el-button {
      height: 3vh;
      font-size: 1.5vh;
    }
  }

  #selector-container {
    width: calc(100% - 5vw);
    flex: 0 0 8vh;
    border: 0.5vh solid var(--el-border-color-base);
    display: flex;
    align-items: center;
    justify-content: space-around;
    background-color: #06237a;
    border-radius: 8px;
    padding-top: 1vh;

    .el-form {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-around;

      .el-button {
        height: 4vh;
        font-size: 1.5vh;
        width:4vw; 
      }

      .el-form-item {
        --font-size: 2vh;
        --el-form-label-font-size: var(--font-size);

        .el-input {
          height: 4vh;

          .el-input__inner {
            height: 4vh;
          }
        }
      }
    }
  }

  //表格无数据样式
.table-area /deep/ .el-table__body-wrapper {
  background-color:#06237a ;
}

#selector-container /deep/ .el-form-item__label {
  color: #f2f2f2;
  font-size: 1.5vh;
  line-height: 3vh;
}
#selector-container /deep/ .el-form-item__label {
  color: #f2f2f2;
  font-size: 1.5vh;
  line-height: 3vh;
}
  
#selector-container /deep/ .el-input__wrapper {
  height: 3.2vh;
  background-color: #0c59b9;
  color: #f2f2f2 !important;
  box-shadow: none;
}
#selector-container /deep/ input {
  color: #f2f2f2;
}


.btn-search {
  width: 6.5vh;
  height: 3vh;
  line-height: 3vh;
  background-color: transparent;
  border: 1px solid #1763FF;
  border-radius: 5px;
}
.btn-reset {
  width:6.5vh;
  height: 3vh;
  line-height: 3vh;
  background-color: transparent;
  border: 1px solid #1763FF;
  color: white;
  border-radius: 5px;
}

  #table-container {
    width: calc(100% - 4.5vw);
    height: 72vh;
    flex: 0 0 72vh;
    border: 0.5vh solid var(--el-border-color-base);

    .el-table {
      width: 100%;
      border-radius: 8px;
    }

    .el-pagination {
      height: 3vh;
    }
  }
}
</style>